import './Button.scss'

const Button = ({ id, label, children, onClick, color }) => {
	return (
		<button
			id={id}
			className={'button'}
			onClick={onClick}
			style={{ color: color ?? 'white' }}
		>
			{label ?? children}
		</button>
	)
}

export default Button
