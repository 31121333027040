import './App.scss'
import ShopPage from './components/multiparty/ShopPage/ShopPage'
import { Home, ShopIndex } from './pages'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import {
	videoCD,
	videoTshirt,
	videoPackClassique,
	videoPackDistance,
	videoPackDMB,
	videoPackMoneytime
} from './assets/video'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { CartProvider } from 'react-use-cart'
import { Footer } from './components/multiparty'
import { useState } from 'react'

function App() {
	const [cartOpen, setCartOpen] = useState(false),
		routes = [
			{ path: '/', element: <Home />, exact: true },
			{
				path: '/shop',
				element: (
					<ShopIndex cartOpen={cartOpen} setCartOpen={setCartOpen} />
				)
			},
			{
				path: '/cd',
				element: (
					<ShopPage
						cartOpen={cartOpen}
						setCartOpen={setCartOpen}
						dataRef={1}
						title={{ label: 'CD CLASSIQUE', color: '#FFFFFF' }}
						subTitle={[`CD version classique`]}
						price={11.99}
						videoSrc={videoCD}
					/>
				)
			},
			{
				path: '/tshirt',
				element: (
					<ShopPage
						cartOpen={cartOpen}
						setCartOpen={setCartOpen}
						dataRef={2}
						title={{
							label: "T-SHIRT L'INSTANT T",
							color: '#FFFFFF'
						}}
						subTitle={[
							'Impression en Sérigraphie',
							'Disponible en édition limitée'
						]}
						price={24.99}
						videoSrc={videoTshirt}
					/>
				)
			},
			{
				path: '/classique',
				element: (
					<ShopPage
						cartOpen={cartOpen}
						setCartOpen={setCartOpen}
						dataRef={3}
						title={{ label: "L'INSTANT T", color: '#FFFFFF' }}
						subTitle={[
							`Pack classique contenant un CD, un t-shirt serigraphié et une affiche`,
							'Disponible en édition limitée'
						]}
						price={34.99}
						videoSrc={videoPackClassique}
					/>
				)
			},
			{
				path: '/distance',
				element: (
					<ShopPage
						cartOpen={cartOpen}
						setCartOpen={setCartOpen}
						dataRef={4}
						title={{ label: 'DISTANCE', color: '#4E697C' }}
						subTitle={[
							`Pack « Distance » contenant un CD, un t-shirt serigraphié et une affiche`,
							'Disponible en édition limitée'
						]}
						price={34.99}
						videoSrc={videoPackDistance}
					/>
				)
			},
			{
				path: '/dmb',
				element: (
					<ShopPage
						cartOpen={cartOpen}
						setCartOpen={setCartOpen}
						dataRef={5}
						title={{ label: 'D.M.B.', color: '#809DCD' }}
						subTitle={[
							`Pack « D.M.B. » contenant un CD, un t-shirt serigraphié et une affiche`,
							'Disponible en édition limitée'
						]}
						price={34.99}
						videoSrc={videoPackDMB}
					/>
				)
			},
			{
				path: '/moneytime',
				element: (
					<ShopPage
						cartOpen={cartOpen}
						setCartOpen={setCartOpen}
						dataRef={6}
						title={{ label: 'MONEY TIME', color: '#EF7A78' }}
						subTitle={[
							`Pack « Money time » contenant un CD, un t-shirt serigraphié et une affiche`,
							'Disponible en édition limitée'
						]}
						price={34.99}
						videoSrc={videoPackMoneytime}
					/>
				)
			}
		]

	return (
		<PayPalScriptProvider
			options={{
				'client-id':
					'Abd_yJ2wRUUMONNTAeZthneNb2D2WpUjlcV2jVrw1uKUznpMp23Um9DI7yxQDyv8Yb8pva4bwqI9YkVz',
				// 'client-id':
				// 	'AclE9gJA5sjeQQOWM0Yf_LGFg88TpVXXCROVU1c5a44GNyE1PnEOXse2TQ244QxbwyM9P8B6NA9r_eHA',
				// 'client-id':
				// 	'AT4ZhpVFdWGTjhUfFSbu_OCvJ2VDFr_6kRDJ8UrKnEO3cS4eZv-eHxyY_j-CDh9y7YEykda3NQ9CAqxl',
				currency: 'EUR',
				intent: 'capture'
				// 'data-client-token':
				// 	'EPUMaET6u4xm-qTTp4hkP4t5KZC3Z8fVQkLDmLQqMxdB2DseRnVcTVrMUnt3PccqRD3DIhZZAH-49wRd'
			}}
		>
			<CartProvider>
				<div className="App">
					<Router>
						<Routes>
							{routes.map((route) => {
								return (
									<Route
										key={route.path}
										path={route.path}
										element={route.element}
										exact={route.exact}
									/>
								)
							})}
						</Routes>
					</Router>
				</div>
				<Footer />
			</CartProvider>
		</PayPalScriptProvider>
	)
}

export default App
