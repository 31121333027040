import './Cart.scss'
import React, { useEffect } from 'react'
import { useCart } from 'react-use-cart'
import { useState } from 'react'
import CartDropdown from './components/CartDropdown'
import { CartModal } from './components'

const Cart = ({ cartOpen, setCartOpen }) => {
	// * Déclarations:
	const { isEmpty, totalUniqueItems, items, updateItemQuantity, removeItem } =
			useCart(),
		[modalOpen, setModalOpen] = useState(false),
		[description, setDescription] = useState(''),
		[total, setTotal] = useState(0)
	// [{ options, isPending }, dispatch] = usePayPalScriptReducer()

	useEffect(() => {
		let totalToState = 0,
			descriptionToState = ''
		items.forEach((item) => {
			let { price, quantity, name } = item
			totalToState += quantity * price
			if (descriptionToState === '') {
				descriptionToState = `${name.label} x ${quantity}`
			} else {
				descriptionToState += ` - ${name.label} x ${quantity}`
			}
		})
		setTotal(totalToState.toFixed(2))
		setDescription(descriptionToState)
	}, [items])

	return (
		<>
			<CartDropdown
				isEmpty={isEmpty}
				totalUniqueItems={totalUniqueItems}
				items={items}
				updateItemQuantity={updateItemQuantity}
				removeItem={removeItem}
				total={total}
				setModalOpen={setModalOpen}
				cartOpen={cartOpen}
				setCartOpen={setCartOpen}
			/>
			<CartModal
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
				total={total}
				description={description}
			/>
		</>
	)
}

export default Cart
