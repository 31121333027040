import React from 'react'
import { Button, Modal } from '../../../templates'

const SellsConditions = ({
	sellsContionsModalOpen,
	setSellsContionsModalOpen
}) => {
	const body = (
		<div id={'footer__legals-body'}>
			<p>
				Les présentes conditions générales de vente (ci-après, les « CGV
				») s’appliquent aux Commandes qui sont effectuées à partir du
				service de vente en ligne de produits de « merchandising »
				lequel est proposé par la Société à partir de e-boutiques
				notamment créées par des artistes et créateurs et pouvant être
				consultées depuis leurs pages personnelles et/ou sites web
				dédiés (ci-après, le « Service »).
			</p>

			<h2>1 – DÉFINITIONS</h2>

			<p>
				« Client » : personne physique, inscrite sur le Service et
				procédant à l’achat en ligne de Produit(s), âgée d’au moins 18
				(dix-huit) ans ou s’engageant, à défaut, à recueillir,
				préalablement à son inscription au Service, l’autorisation de
				ses parents ou de son tuteur exerçant l’autorité parentale.
				<br />
				<br />
				« Commande » : tout achat de Produit(s) effectué à partir du
				Service par un Client. La Commande est considérée comme
				effectuée à compter de la réalisation du paiement par le Client.
				<br />
				<br />
				« Société » : DOUBLE T, 41 Rue Frédéric Petit, 80000 AMIENS. La
				Société peut être contactée par courrier à l’adresse mentionnée
				ciavant ou par email à l’adresse suivante :
				theoklmprod@gmail.com
				<br />
				<br />
				« Produits » : produits de « merchandising » physique (tels que
				des vêtements, livres, produits de papeterie, goodies etc.)
				reproduisant du contenu lié à un groupe, à un artiste, à un
				créateur ou à une marque ainsi que tout support d’enregistrement
				physique de type CD ou DVD et/ou tout autre type de produit que
				la Société serait amenée à commercialiser sur le Service.
				<br />
				<br />« Service Client » ou « Service » : service mis à la
				disposition du Client par la Société du lundi au vendredi de 9h
				à 17h permettant le traitement de leur commande par mail.
			</p>

			<h2>2 – OBJET DES CGV</h2>

			<p>
				Les présentes CGV déterminent les droits et obligations de la
				Société et du Client dans le cadre de la vente en ligne des
				Produits proposés par la Société.
			</p>

			<h2>3 – APPLICATION DES CGV</h2>

			<p>
				Les présentes CGV entrent en vigueur dès que le Client passe
				Commande dans les conditions de l’article 5 ci-après. Le Client
				s’engage ainsi à consulter les présentes CGV et à les valider,
				avant chaque Commande en cochant, conformément à l’article 5.2,
				la case prévue à cet effet.
				<br />
				<br />
				Les présentes CGV régissent les ventes de Produits, effectuées
				au travers du site Internet de la Société, et sont partie
				intégrante du contrat entre la Société et le Client. Elles sont
				pleinement opposables au Client qui les a acceptées avant de
				passer Commande.
				<br />
				<br />
				Les présentes CGV que la Société se réserve la faculté de
				modifier à tout moment, par la publication d’une nouvelle
				version sur son site Internet, prévalent, sauf conditions
				particulières émises par la Société, sur tous autres documents
				ou conditions, ce que le Client déclare expressément connaître
				et accepter, sans restriction ni réserve. Seule la version des
				CGV qui est en ligne au moment où le Client passe Commande,
				conformément à l’article 5.2, s’applique entre la Société et le
				Client.
				<br />
				<br />
				Le Client reconnaît qu’il a bénéficié des conseils et
				informations nécessaires afin de s’assurer de l’adéquation de
				l’offre à ses besoins. Le Client déclare être en mesure de
				contracter légalement en vertu des lois françaises ou
				valablement représenter la personne physique ou morale pour
				laquelle il s’engage.
				<br />
				<br />
				Sauf preuve contraire, les informations enregistrées par la
				Société constituent la preuve de l’ensemble des transactions.
			</p>

			<h2>4 – CRÉATION DE COMPTE CLIENT</h2>

			<p>
				4.1 L’accès au Service nécessite l’inscription préalable du
				Client au moyen du formulaire qui est mis à sa disposition lors
				de sa première connexion, réalisée en vue de passer Commande, à
				partir du Service ou la création préalable d’un Compte Client.
				Dans le cadre de son inscription, le Client transmet à la
				Société les éléments d’identification suivants : • Nom / Prénom
				• Dénomination sociale de la société d’appartenance (le cas
				échéant) • E-mail • Adresse de livraison / Adresse de
				facturation
				<br />
				<br />
				4.2 Toute demande d’inscription dûment complétée est transmise à
				la Société qui en accuse réception par e-mail et permet
				l’activation d’un Compte Client.
				<br />
				<br />
				4.3 Le Client est seul responsable de la confidentialité de son
				mot de passe et de son identifiant ainsi que de toute Commande
				passée par l’intermédiaire de son Compte Client. Le Client
				s’engage par ailleurs à ce que les informations transmises dans
				le cadre de son inscription soient exactes, complètes et
				régulièrement mises à jour à partir de son Compte Client.
			</p>

			<h2>5 – COMMANDES</h2>

			<p>
				5.1 Les Commandes peuvent être passées dans le territoire de la
				France Métropolitaine, la Belgique et la Suisse. Toute Commande
				réalisée par un Client résidant hors du territoire français sera
				traitée conformément aux présentes CGV, et ce sous réserve des
				dispositions législatives autrement applicables dans le pays où
				le Client passant Commande est domicilié.
				<br />
				<br />
				5.2 Conformément aux dispositions de l’article 1127-1 du Code
				civil, pour passer Commande le Client s’engage à respecter la
				procédure suivante consistant à : <br />• sélectionner ses
				Produits (en renseignant la taille et les quantités souhaitées)
				; • visualiser son récapitulatif de Commande (affichant le
				détail des prix de chaque Produit ainsi que le prix total de sa
				Commande) ; • sélectionner son mode de livraison ; • valider ses
				coordonnées et sa Commande en cliquant sur le lien « Continuer
				vers le paiement » ; et • procéder à son règlement en cliquant
				sur le lien « Valider le paiement » ou tout autre lien indiquant
				que la passation d’une commande oblige à son paiement.
				<br />
				<br />
				Le Client dispose, sous réserve qu’il n’ait pas encore confirmé
				son paiement, de la possibilité de revenir à tout moment sur le
				choix de ses Produits, de rectifier d’éventuelles erreurs
				commises lors de la saisie de sa Commande et/ou de modifier tout
				ou partie de ses données d’identification (incluant l’adresse de
				facturation et/ou de livraison).Toute confirmation de paiement
				nécessite l’acceptation des présentes CGV que le Client s’engage
				à valider, après en avoir intégralement pris connaissance, en
				cochant la case prévue à cet effet.
				<br />
				<br />
				La confirmation de la Commande emportera formation du contrat.
				La langue proposée pour la conclusion du contrat est la langue
				française pour les Clients utilisant la navigation en langue
				française.
				<br />
				<br />
				Les parties conviennent que les illustrations ou photos des
				Produits offerts à la vente n’ont pas de valeur contractuelle.
				<br />
				<br />
				5.3 Le règlement de la Commande s’effectue via le service de
				paiement PayPal par carte bancaire (Visa, American Express,
				Master Card, autres cartes bancaires) ou directement avec le
				compte PayPal du Client.
				<br />
				<br />
				En communiquant ses informations bancaires lors de la vente, le
				Client autorise la Société à débiter sa carte du montant relatif
				au prix indiqué. Le Client confirme qu’il est bien le titulaire
				légal de la carte à débiter et qu’il est légalement en droit
				d’en faire usage. En cas d’erreur, ou d’impossibilité de débiter
				la carte, la vente est immédiatement résolue de plein droit et
				la commande annulée.
				<br />
				<br />
				Toute Commande par le Client est débitée avant expédition.
				<br />
				<br />
				5.4 Un e-mail de confirmation de commande commençant par # est
				adressé au Client dès encaissement de son règlement et après
				vérification par la Société de la disponibilité en stocks des
				Produits. L’envoi par la Société d’un e-mail de confirmation
				valide définitivement la Commande du Client. Toutefois, et si
				quelle qu’en soit la raison, la Société n’est pas en mesure de
				donner suite à une Commande, elle en informe le Client par
				e-mail dans les meilleurs délais et procède, sous réserve
				qu’elle en ait déjà encaissé le montant, à son remboursement.
				<br />
				<br />
				5.5 L’e-mail de confirmation de Commande que la Société adresse
				conformément à l’article 5.4 au Client contient un lien pour se
				connecter à son compte afin de télécharger, au format pdf, la
				version des CGV qui sont en vigueur à la date de validation de
				la Commande du Client conformément à l’article 5.2. Cet e-mail
				indique également une date estimée de livraison.
				<br />
				<br />
				L’archivage des communications, de la Commande, des détails de
				la Commande, ainsi que des factures est effectué sur un support
				fiable et durable de manière à constituer, dans l’intérêt du
				Client, une copie fidèle et durable.
			</p>

			<h2>6 – PRIX</h2>

			<p>
				Le prix des Produits est indiqué en euros toutes taxes comprises
				(incluant les frais de traitement). Celui-ci ne comporte pas les
				frais de livraison facturés en supplément.
				<br />
				<br />
				Dans tous les cas, le montant total dû par le Client incluant
				les frais d’expédition est indiqué sur la page de confirmation
				de la Commande.
				<br />
				<br />
				Des droits de douane ou autres taxes locales ou droits
				d’importation ou taxes d’état sont susceptibles d’être exigibles
				dans certains cas. Ces droits et sommes ne sont pas à la charge
				de la Société. Ils seront à la charge de l’acheteur et relèvent
				de sa responsabilité exclusive (déclarations, paiement aux
				autorités compétentes, etc.). La Société invite le Client à se
				renseigner sur ces aspects auprès des autorités locales
				correspondantes.
				<br />
				<br />
				Toute commande est facturée sur la base des tarifs en vigueur au
				moment de la validation de la Commande du Client conformément à
				l’article 5.2.
				<br />
				<br />
				La Société se réserve le droit de modifier ses prix à tout
				moment pour l’avenir, tout en garantissant l’application du prix
				indiqué au moment de la Commande.
				<br />
				<br />
				Les Produits demeurent la propriété de la Société jusqu’au
				complet paiement du prix. Le complet paiement du prix emporte
				transfert au bénéfice du Client de la propriété des Produits.
			</p>

			<h2>7 – LIVRAISON</h2>

			<p>
				7.1 Les Produits sont livrés à l’adresse transmise par le Client
				et dans le respect du délai de livraison indiqué dans l’e-mail
				de confirmation que la Société adresse, conformément à l’article
				5.4, à réception de son règlement au Client. Ce délai de
				livraison ne prend pas en compte le délai de préparation de la
				Commande.
				<br />
				<br />
				Pour les Produits indiqués « en précommande », le délai de
				livraison ne prend pas en compte le délai d’acheminement des
				Produits à la Société.
				<br />
				<br />
				La Société rappelle qu’au moment où le Client prend possession
				physiquement des Produits, les risques de perte ou
				d’endommagement des Produits lui sont transférés. Il appartient
				donc au Client de notifier au transporteur toute éventuelle
				réserve sur le Produit livré.
				<br />
				<br />
				7.2 Les différents modes de livraison suivants sont proposés au
				Client :
				<br />
				<br />• Livraison à domicile suivie – sans signature : Pour la
				France métropolitaine (y compris la Corse) : livraison avec un
				délai moyen de 2 (deux) jours ouvrables à 7 (sept) jours ouvrés
				à l’adresse de livraison indiquée. Si le Client est absent et
				que la taille du colis ne permet pas une remise dans la boîte
				aux lettres, un avis de passage lui est déposé et le colis est
				mis à sa disposition dans point de dépôt auquel le Client est
				rattaché. Le Client dispose d’un délai de 15 (dix) jours
				ouvrables à compter de l’arrivée du colis au bureau de poste
				pour venir le retirer. Passé ce délai, il sera retourné à la
				Société. Pour les destinations autres que la France
				métropolitaine (y compris la Corse) : livraison avec un délai
				moyen de 3 (cinq) à 15 (quinze) jours ouvrés selon la
				destination de livraison indiquée. Si le Client est absent et
				que la taille du colis ne permet pas une remise dans la boîte
				aux lettres, un avis de passage lui est déposé et le colis est
				mis à sa disposition dans un point de dépôt auquel le Client est
				rattaché. Le Client dispose d’un délai de 15 (quinze) jours
				ouvrables à compter de l’arrivée du colis au point de dépôt pour
				venir le retirer. Passé ce délai, il sera retourné à la Société.
				<br />
				<br />
				• Livraison à domicile suivie – contre signature Livraison à
				domicile suivie contre signature avec un délai moyen de 2 (deux)
				à 7 (sept) jours ouvrables pour la France, selon la destination
				de livraison indiquée, et 3 (cinq) à 15 (quinze) jours ouvrés,
				pour les destinations autres que la France métropolitaine (y
				compris la Corse), selon la destination de livraison indiquée.
				Le colis en livraison à domicile suivie contre signature sera
				remis à l’adresse indiquée par le Client contre la signature du
				destinataire ou celle de toute personne attachée à son service,
				demeurant avec lui ou mandatée spécialement. La signature du
				destinataire n’est pas systématiquement mise à disposition du
				Client. Le cas échéant, la signature numérisée du destinataire,
				de toute personne attachée à son service, demeurant avec lui, ou
				de la personne spécialement mandatée, ainsi que sa reproduction
				font preuve de livraison du colis. Le refus de signature
				équivaut à un refus de l’envoi. Un code confidentiel pourra
				également être transmis au destinataire par SMS ou email avant
				la livraison du colis. Le colis pourra être remis à l’adresse
				indiquée contre la transmission de ce code confidentiel. La
				concordance des codes fait preuve de livraison du colis et vaut
				signature.
				<br />
				<br />
				Si le Client est absent, un avis de passage lui est déposé et le
				colis est mis à sa disposition dans point de dépôt auquel le
				Client est rattaché.
				<br />
				<br />
				7.3 En cas de retard d’expédition, le Client reçoit un e-mail
				lui indiquant une nouvelle date de livraison.
			</p>

			<h2>8 – RETRACTATION</h2>

			<p>
				8.1 Dans le respect des dispositions législatives en vigueur le
				Client dispose sous réserve de l’article 8.3, d’un délai de 14
				(quatorze) jours à compter de la réception de la Commande pour
				exercer son droit de rétractation prévu à l’article L. 221-18 du
				Code de la consommation et ce sans avoir à motiver sa décision.
				<br />
				<br />
				8.2 La demande de rétractation est adressée à la Société par le
				Client par mail à l’adresse theokmprod@gmail.com
				<br />
				<br />
				Les Produits sont renvoyés par le Client par courrier, à ses
				frais, à l’adresse du lieu d’expédition des Produits ou à toute
				autre adresse qui lui serait indiqué à partir de son Compte
				Client par la Société au plus tard 14 (quatorze) jours suivant
				la date d’envoi, par le Client, à la Société de son formulaire
				de rétractation.
				<br />
				<br />
				8.3 Conformément à l’article L. 221-28, 9° du Code de la
				consommation, le droit de rétractation ne peut être exercé (i)
				si les Produits ont été descellés, (ii) plus généralement s’ils
				ont été portés, consommés ou endommagés par le Client, (iii)
				s’il s’agit de produits de billetterie ou (iv) s’il s’agit de
				produits personnalisés (par exemple, sans que cette liste ne
				soit exhaustive, les produits dédicacés nominativement par
				l’artiste et les produits imprimés avec un message au choix du
				Client).
				<br />
				<br />
				Achat ou accès aux billets de spectacles seul ou au sein d’une
				offre commerciale : Conformément à l’article L221-28 du Code de
				la consommation, le droit de rétractation ne peut être exercé
				dans le cadre d’activités de loisirs devant être fournies à une
				date ou à une période déterminée.
				<br />
				<br />
				A cet égard, en cas d’achat d’un produit ou d’un service donnant
				accès à l’achat d’un billet de spectacle ou l’accès à une
				prévente de billet de spectacle ou de tout événement similaire
				(notamment l’accès à une release party), le droit de
				rétractation ne peut être exercé par le Client, y compris
				lorsque l’accès à la prévente du spectacle ou le billet de
				spectacle fait partie d’une offre commerciale incluant l’achat
				d’autres produits et/ou services.
				<br />
				<br />
				Conformément à l’article L. 221-28, 5° du Code de la
				consommation, le droit de rétractation ne peut être exercé si
				les Produits ont été descellés par le Client après la livraison
				et ne peuvent être renvoyés pour des raisons d’hygiène ou de
				protection de la santé. Sont en particulier concernées, les
				denrées alimentaires.
				<br />
				<br />
				Le Client ne peut pas se rétracter lorsque le Produit est un
				bien susceptible de se détériorer ou de se périmer rapidement
				comme les produits alimentaires frais.
				<br />
				<br />
				En cas de rétractation, les Produits doivent donc être
				impérativement retournés à la Société dans leur emballage
				d’origine et, en tout état de cause, dans leur état d’origine (à
				savoir état neuf permettant à la Société de proposer à nouveau
				les Produits à la commercialisation). Par conséquent, (i) tout
				vêtement porté ou ayant subi des dégradations, même infimes,
				(ii) tout CD, DVD ou produit alimentaire qui aurait été descellé
				et (iii) tout Produit ayant été endommagés par le fait du Client
				ne sera ni repris ni remboursé par la Société.
				<br />
				<br />
				8.4 En cas de rétractation conformément au présent article 8, la
				Société est tenue de rembourser, à l’exclusion des frais de
				renvoi des Produits qui sont à la charge du Client, la totalité
				des sommes versées (incluant les frais de livraison) par le
				Client et ce au plus tard 14 (quatorze) jours suivant la date à
				laquelle la Société reçoit le formulaire de rétractation du
				Client.
			</p>

			<h2>9 – RÉSOLUTION DU CONTRAT</h2>

			<p>
				9.1 Conformément aux articles L. 216-1 et suivants du Code de la
				consommation, et dans l’hypothèse où la Société n’aurait pas
				respecté le délai de livraison indiqué dans l’e-mail de
				confirmation adressé au Client selon l’article 5.4 ci-avant, la
				Société s’engage à en avertir, dans les meilleurs délais, par
				e-mail le Client. Le Client pourra selon son choix, dans les 24
				(vingt-quatre) heures, suivant la réception de la notification
				de la Société :
				<br />
				<br />• accepter la nouvelle date de livraison proposée par la
				Société, ou • annuler la Commande, si celle-ci n’a pas été
				expédiée au moment de la notification du Client.
				<br />
				<br />
				Si la Société ne s’est pas exécutée dans ce délai
				supplémentaire, le Client dispose sous réserve de l’article 9.3,
				de la possibilité d’annuler sa Commande par courrier recommandé
				avec accusé de réception adressé à la Société après l’envoi
				d’une première mise en demeure, adressée par courrier recommandé
				avec accusé de réception à la Société lui enjoignant d’exécuter
				la livraison dans les meilleurs délais, restée sans effet.
				<br />
				<br />
				La Commande est, dans cette hypothèse, annulée à réception par
				la Société du courrier recommandé avec accusé de réception lui
				notifiant ladite annulation et ce sauf à ce que la Commande ait
				été effectivement livrée, à la date de réception dudit courrier,
				par la Société.
				<br />
				<br />
				9.2 En cas d’annulation, conformément au présent article 9, la
				Société est tenue de rembourser la totalité des sommes versées
				(incluant les frais de livraison) par le Client et ce au plus
				tard 14 (quatorze) jours suivant la date à laquelle la Société
				reçoit le courrier du Client lui notifiant l’annulation.
				<br />
				<br />
				Les retours de Produits doivent faire l’objet de l’accord
				préalable de la Société. Tout Produit réexpédié sans accord
				préalable de la Société ne pourra donner lieu à remboursement.
				<br />
				<br />
				9.3 En tout état de cause, aucune annulation n’est possible en
				cas de retard de livraison dû à une erreur d’adresse imputable
				au Client.
			</p>

			<h2>10 – ENGAGEMENT DU CLIENT</h2>

			<p>
				10.1 Le Client déclare être majeur ou s’engage à défaut à
				recueillir, préalablement à son inscription, l’autorisation de
				ses parents ou de son tuteur exerçant l’autorité parentale,
				ce(s) dernier(s) demeurant, en toute hypothèse, garant(s) du
				respect des présentes CGV, ce que le Client déclare expressément
				connaître et accepter.
				<br />
				<br />
				10.2 Le Client s’engage à effectuer un usage strictement
				personnel et exclusif de toute finalité commerciale des Produits
				commandés à partir du Service.
				<br />
				<br />
				10.3 Le Client s’engage plus généralement à se conformer à
				l’ensemble des termes et conditions des présentes CGV ainsi qu’à
				conserver, au besoin en les imprimant, tous les exemplaires des
				CGV qui lui sont envoyés par la Société conformément à l’article
				5.4 ci-avant.
			</p>

			<h2> 11 – CONFORMITÉ</h2>

			<p>
				11.1 La Société est garante de la conformité des biens au
				contrat. Les Produits vendus à partir du Service bénéficient de
				la garantie légale de conformité prévue aux articles L. 217-4 à
				L. 217-12 du Code de la consommation, de la garantie relative à
				la sécurité et à la santé des personnes prévue à l’article L.
				411-1 du Code de la consommation et de la garantie légale des
				vices cachés prévue aux articles 1641 à 1649 du Code civil.
				<br />
				<br />
				11.2 La Société, au titre de ces garanties, s’engage, au choix
				du Client, à lui rembourser ou à échanger tout Produit
				défectueux, qui aurait subi des détériorations avant réception
				et/ou qui ne correspondrait pas à la Commande du Client et ce
				sous réserve que le Client l’en informe immédiatement, dès
				réception du Produit, en contactant le Service Client par e-mail
				ou courrier à l’adresse postale suivante : 41 RUE FREDERIC PETIT
				80000 AMIENS
				<br />
				<br />
				11.3 Garantie légale de conformité : La Société assurera
				l’échange et/ou le remboursement desdits Produits et ce sous
				réserve, en cas de Commande non conforme, que les Produits
				concernés lui soient retournés dans les conditions de l’article
				8.3 et en cas de détériorations, que la mention en soit
				impérativement portée sur le bon de livraison. Les frais de
				retour des Produits seront remboursés par la Société au Client
				sur présentation du justificatif afférent.
				<br />
				<br />
				Lorsqu’il agit en garantie légale de conformité, le Client
				bénéficie d’un délai de 2 (deux) ans à compter de la délivrance
				du Produit pour demander à la Société, à son choix, la
				réparation ou le remplacement du bien, sous réserve des
				conditions de coût prévues par l’article L. 217-9 du Code de la
				consommation.
				<br />
				<br />
				La garantie légale de conformité s’applique indépendamment de la
				garantie commerciale éventuellement consentie.
				<br />
				<br />
				11.4 Garantie légale des vices cachés : Le Client peut décider
				de mettre en œuvre la garantie contre les défauts cachés de la
				chose vendue au sens de l’article 1641 du Code civil en
				choisissant entre la résolution de la vente ou une réduction du
				prix de vente conformément aux dispositions de l’article 1644 du
				Code civil.
				<br />
				<br />
				11.5 La Société s’engage à réaliser l’archivage de toute
				Commande (incluant les bons de livraison, les factures et la
				version en vigueur des présentes CGV) dont le montant est
				supérieur à 120 euros H.T, et ce conformément aux dispositions
				législatives en vigueur, pour une durée de 10 (dix) ans.
				<br />
				<br />
				Pendant cette durée, la Société s’engage à communiquer au
				Client, dès que celui-ci en fait la demande, la facture
				correspondant à sa Commande et les CGV en vigueur au moment de
				la Commande
			</p>

			<h2>12 – DROITS DE PROPRIÉTÉ INTELLECTUELLE</h2>

			<p>
				Les marques, noms de domaines, logiciels, images, vidéos, textes
				ou plus généralement toute information objet de droits de
				propriété intellectuelle sont et restent la propriété exclusive
				de leur auteur. Aucune cession de droits de propriété
				intellectuelle n’est réalisée au travers des présentes CGV.
				Toute reproduction totale ou partielle, modification ou
				utilisation de ces biens pour quelque motif que ce soit est
				strictement interdite.
			</p>

			<h2>13 – DONNEES A CARACTERE PERSONNEL</h2>

			<p>
				Le Client est informé que la Société est responsable du
				traitement, est amenée à collecter et à traiter, des données à
				caractère personnel concernant le Client (ci-après, les «
				Données »).
				<br />
				<br />
				La base légale de ce traitement est contractuelle. Le traitement
				des Données est nécessaire au traitement de la commande du
				Client par la Société. A défaut de fourniture des Données, la
				commande ne pourra être prise en compte.
				<br />
				<br />
				Les Données transmises par le Client sont les Données suivantes
				: nom, prénom, adresse postale, adresse électronique, numéro de
				téléphone, date de naissance. Les informations de paiement sont
				transmises uniquement au service de paiement PayPal.
				<br />
				<br />
				Les Données fournies par le Client sont traitées par la Société
				pour les finalités suivantes :
				<br />
				<br />• gestion de la Commande ; • vente et livraison des
				Produits ; • finalisation de la transaction ; • gestion des
				relations avec l’acheteur.
				<br />
				<br />
				Toute autre utilisation des Données du Client sera soumise à son
				autorisation préalable écrite.
				<br />
				<br />
				Les Données collectées sont conservées pour les durées suivantes
				:
				<br />
				<br />• les Données relatives aux paiements effectués par le
				Client, notamment les données bancaires, sont conservées,
				conformément à la politique d’utilisation du service de paiement
				PayPal et de leurs CGV. • les autres Données collectées sont
				conservées pour une durée de 36 (trente-six) mois à compter du
				dernier achat.
				<br />
				<br />
				Les Données collectées sont à l’usage exclusif de la Société.
				<br />
				<br />
				Conformément aux dispositions de la loi du 6 janvier 1978
				modifiée dite « Informatique et Libertés » et du RGPD, le Client
				dispose sur les Données qui le concernent d’un droit d’accès, de
				rectification, de portabilité et d’effacement. Il dispose
				également de droits de limitation et d’opposition au traitement
				et du droit à la portabilité de ses Données.
				<br />
				<br />
				Enfin, le Client dispose du droit de définir des directives
				relatives à la conservation, à l’effacement et à la
				communication de ses Données après son décès.
				<br />
				<br />
				Pour exercer ses droits, le Client peut adresser une demande par
				courrier postal adressé à DOUBLE T 41 RUE FREDERIC PETIT 80000
				AMIENS, ou par courriel à l’adresse suivante :
				theoklmprod@gmail.com. Cette demande doit comprendre les
				coordonnées du Client et justifier, par tous moyens, de son
				identité. En cas de doute sur l’identité du Client, la Société
				se réserve le droit de demander des informations supplémentaires
				apparaissant nécessaires, y compris la photocopie d’un titre
				d’identité portant la signature du Client.
				<br />
				<br />
				Le Client dispose également, le cas échéant, d’un droit de
				recours auprès de la CNIL, 3 PLACE DE FONTENOY – TSA 80715 –
				75334 PARIS CEDEX 07, autorité de contrôle en charge du respect
				des obligations en matière de données à caractère personnel.
				<br />
				<br />
				Le Client est informé que le responsable de ses données à
				caractère personnel est la Société.
			</p>

			<h2>14 – FORCE MAJEURE</h2>

			<p>
				La responsabilité de la Société ne pourra pas être mise en cause
				si la non-exécution ou le retard dans l’exécution de l’une de
				ses obligations décrites dans les présentes CGV résulte d’un cas
				de force majeure, tel que défini et retenu par l’article 1218 du
				Code civil et la jurisprudence des cours et des tribunaux
				français. La Société avisera le Client de la survenance d’un tel
				évènement dès que possible.
			</p>

			<h2>15 – DROIT APPLICABLE</h2>

			<p>
				Toutes les clauses figurant dans les présentes CGV, ainsi que
				toutes les opérations d’achat et de vente qui y sont visées,
				seront soumises au droit français.
			</p>

			<h2>16 – CLAUSES</h2>

			<p>
				La nullité d’une clause contractuelle n’entraîne pas la nullité
				des présentes conditions générales de vente.
			</p>

			<h2>17 – JURIDICTION COMPÉTENTE</h2>

			<p>
				Le Client peut saisir, soit l’une des juridictions
				territorialement compétentes en vertu du Code de la procédure
				civile, soit la juridiction du lieu où il demeurait au moment de
				la conclusion des CGV.
			</p>
		</div>
	)

	return (
		<Modal
			open={sellsContionsModalOpen}
			setOpen={setSellsContionsModalOpen}
			content={{
				header: 'CONDITIONS GÉNÉRALES DE VENTES',
				body,
				footer: (
					<div>
						<Button
							onClick={() => setSellsContionsModalOpen(false)}
							label={'Fermer'}
							color={'black'}
						/>
					</div>
				)
			}}
		/>
	)
}

export default SellsConditions
