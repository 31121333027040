import './ShopPage.scss'
import { logo } from '../../../assets/img'
import { BsArrowRight } from 'react-icons/bs'
import { IoIosArrowDown } from 'react-icons/io'
import { Button, Video } from '../../templates'
import { useNavigate } from 'react-router-dom'
import { useCart } from 'react-use-cart'
import Cart from '../Cart/Cart'

const ShopPage = ({
	cartOpen,
	setCartOpen,
	dataRef,
	title,
	subTitle,
	price,
	videoSrc
}) => {
	const { color, label } = title,
		navigate = useNavigate(),
		{ addItem } = useCart()

	return (
		<div id={'shopPage'}>
			<div id={'shopPage__header'}>
				<img
					src={logo}
					alt="Logo Double T"
					onClick={() => navigate('/')}
				/>
				<Cart cartOpen={cartOpen} setCartOpen={setCartOpen} />
			</div>
			<div id={'shopPage__body'}>
				<div>
					<div id={'shopPage__title'} style={{ color }}>
						{label}
					</div>
					<div id={'shopPage__sub-title'}>
						{subTitle.map((sentence) => {
							return (
								<div
									key={`subtitle-${subTitle.indexOf(
										sentence
									)}`}
								>
									{sentence}
								</div>
							)
						})}
					</div>
					<div id={'shopPage__price'}>{price} €</div>
					<Button
						id={'shopPage__purchase-btn'}
						onClick={() => {
							setCartOpen(true)
							addItem({
								id: dataRef,
								dataRef,
								name: title,
								price
							})
							document.querySelector('#cart__icon').click()
						}}
					>
						Ajouter au panier <BsArrowRight />
					</Button>
				</div>
			</div>
			<div id={'shopPage__return-btn'} onClick={() => navigate('/shop')}>
				<div>Retour au shop</div>
				<IoIosArrowDown />
			</div>
			<Video src={videoSrc} />
		</div>
	)
}

export default ShopPage
