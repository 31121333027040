import './Footer.scss'
import {
	SlSocialFacebook,
	SlSocialInstagram,
	SlSocialYoutube
} from 'react-icons/sl'
import { CiMail } from 'react-icons/ci'
import { useState } from 'react'
import { LegalsMentions, SellsConditions } from './components'

const Footer = () => {
	const [legalsModalOpen, setLegalsModalOpen] = useState(false),
		[sellsContionsModalOpen, setSellsContionsModalOpen] = useState(false)

	return (
		<>
			<div id={'footer'}>
				<div id={'footer__copyright'}>
					Copyright © 2023, Double T. <br /> Design par{' '}
					<a
						href={'https://www.instagram.com/yuleniad/'}
						target={'_blank'}
					>
						Yulen Iriarte
					</a>
					, Dev par{' '}
					<a
						href={'https://www.linkedin.com/in/vincent-mancheron/'}
						target={'_blank'}
					>
						Vincent Mancheron
					</a>
				</div>
				<div id={'footer__social'}>
					<a
						href="https://www.facebook.com/Double.Tprod"
						target={'_blink'}
					>
						<SlSocialFacebook id={'fb'} />
					</a>
					<a
						href="https://www.instagram.com/doublet_off/"
						target={'_blink'}
					>
						<SlSocialInstagram id={'ig'} />
					</a>
					<a
						href="https://www.youtube.com/@doublet_off"
						target={'_blink'}
					>
						<SlSocialYoutube id={'yt'} />
					</a>
				</div>
				<div id={'footer__legals'}>
					<a href={'mailto:theoklmprod@gmail.com'}>
						<CiMail id={'mail'} /> Contact
					</a>
					<p onClick={() => setSellsContionsModalOpen(true)}>CGV</p>
					<p onClick={() => setLegalsModalOpen(true)}>
						Mentions légales
					</p>
				</div>
			</div>
			<LegalsMentions
				legalsModalOpen={legalsModalOpen}
				setLegalsModalOpen={setLegalsModalOpen}
			/>
			<SellsConditions
				sellsContionsModalOpen={sellsContionsModalOpen}
				setSellsContionsModalOpen={setSellsContionsModalOpen}
			/>
		</>
	)
}

export default Footer
