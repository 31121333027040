import { Button, Modal } from '../../../templates'

const LegalsMentions = ({ legalsModalOpen, setLegalsModalOpen }) => {
	return (
		<Modal
			open={legalsModalOpen}
			setOpen={setLegalsModalOpen}
			content={{
				header: 'MENTIONS LEGALES',
				body: (
					<p>
						DOUBLE T
						<br />
						<br />
						Siège social :<br />
						<br />
						41 RUE FREDERIC PETIT
						<br />
						<br />
						80000 AMIENS - FRANCE
						<br />
						<br />
						Directeur de la publication : Théo Descombes
						<br />
						<br />
						Le site est hebergé par :<br />
						<br />
					</p>
				),
				footer: (
					<div>
						<Button
							onClick={() => setLegalsModalOpen(false)}
							label={'Fermer'}
							color={'black'}
						/>
					</div>
				)
			}}
		/>
	)
}

export default LegalsMentions
