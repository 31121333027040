import './Video.scss'

const Video = ({ src }) => {
	return (
		<video id={'video'} autoPlay loop muted playsInline>
			<source src={src} type="video/mp4" />
		</video>
	)
}

export default Video
