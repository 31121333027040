import { cartLogo } from '../../../../assets/img'
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Button } from '../../../templates'
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai'
import { RxCrossCircled } from 'react-icons/rx'

const CartDropdown = ({
	isEmpty,
	totalUniqueItems,
	items,
	updateItemQuantity,
	removeItem,
	total,
	setModalOpen,
	cartOpen,
	setCartOpen
}) => {
	// * Logo Panier:
	const CustomToggle = React.forwardRef(({ onClick }, ref) => (
		<img
			ref={ref}
			id={'cart__icon'}
			src={cartLogo}
			alt="Panier"
			onClick={(e) => {
				setCartOpen(!cartOpen)
				e.preventDefault()
				onClick(e)
			}}
			onMouseOver={(e) => {
				setCartOpen(true)
				e.preventDefault()
				onClick(e)
			}}
		/>
	))

	const CustomMenu = React.forwardRef(
		({ 'aria-labelledby': labeledBy }, ref) => {
			if (cartOpen) {
				return isEmpty ? (
					<div
						ref={ref}
						aria-labelledby={labeledBy}
						className={'cart__dropdown cart__dropdown--empty'}
						onMouseOut={() => setCartOpen(false)}
					>
						<p>Panier vide</p>
					</div>
				) : (
					<div
						ref={ref}
						aria-labelledby={labeledBy}
						className={'cart__dropdown cart__dropdown--full'}
						onMouseLeave={() => setCartOpen(false)}
					>
						<h2 id={'cart__title'}>
							{totalUniqueItems} Produit
							{totalUniqueItems > 1 && 's'}
						</h2>

						<ul id={'cart__list'}>
							{items.map((item) => (
								<li key={item.id}>
									<div>
										{item.quantity} x {item.name.label}
									</div>

									<div className={'cart__dash'}>&mdash;</div>

									<div>
										<AiOutlinePlusCircle
											onClick={() =>
												updateItemQuantity(
													item.id,
													item.quantity + 1
												)
											}
										/>
										<AiOutlineMinusCircle
											onClick={() =>
												updateItemQuantity(
													item.id,
													item.quantity - 1
												)
											}
										/>
										<RxCrossCircled
											onClick={() => removeItem(item.id)}
										/>
									</div>
								</li>
							))}
						</ul>
						<div id={'cart__payment-btn'}>
							<div>Total: {total} €</div>
							<Button
								label={'Payer'}
								onClick={() => setModalOpen(true)}
							/>
						</div>
					</div>
				)
			}
		}
	)

	return (
		<Dropdown id={'cart'}>
			<Dropdown.Toggle as={CustomToggle} />
			<Dropdown.Menu size={'lg'} as={CustomMenu} />
		</Dropdown>
	)
}

export default CartDropdown
