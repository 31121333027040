import { logo } from '../../assets/img'
import { Button } from '../../components/templates'
import { useNavigate } from 'react-router-dom'
import './Home.scss'
import { videoBGHome } from '../../assets/video'
import { BsArrowRight } from 'react-icons/bs'
// import { Player } from 'video-react'
import { Video } from '../../components/templates'

const Home = () => {
	const navigate = useNavigate()

	return (
		<div id={'home'}>
			<div id={'home__content'}>
				<div id={'home__logo'}>
					<img src={logo} alt="Logo Double T" />
				</div>
				<div id={'home__link'}>
					<div id={'home__sub-title'}>
						Premier album disponible en précommande
					</div>
					<Button
						id={'home__access_btn'}
						onClick={() => navigate('/shop')}
					>
						PRÉ-COMMANDER MAINTENANT <BsArrowRight />
					</Button>
				</div>
			</div>
			<Video src={videoBGHome} />
			{/* <Player src={videoBGHome} /> */}
		</div>
	)
}

export default Home
