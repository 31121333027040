const Card = ({ src, alt, title, subTitle, onClick }) => {
	return (
		<div className={'shopIndex__card'} onClick={onClick}>
			<img src={src} alt={alt} />
			<div className={'shopIndex__title'}>{title}</div>
			{subTitle && <div>Disponible en édition limitée</div>}
		</div>
	)
}

export default Card
