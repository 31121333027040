import {
	cd,
	packClassic,
	packDMB,
	packDistance,
	packMoneytime,
	coverTitle,
	logo,
	tshirt
} from '../../assets/img'
import { Cart } from '../../components/multiparty'
import './ShopIndex.scss'
import { Row, Col } from 'react-bootstrap'
import { Card } from './components'
import { useNavigate } from 'react-router-dom'

const ShopIndex = ({ cartOpen, setCartOpen }) => {
	const navigate = useNavigate()

	return (
		<div id={'shopIndex'}>
			<div id={'shopIndex__header'}>
				<div>
					<img
						className={'shopIndex__logos'}
						src={logo}
						alt="Logo Double T"
						onClick={() => navigate('/')}
					/>
				</div>
				<div>
					<img
						id={'shopIndex__cover-title'}
						src={coverTitle}
						alt="Instant T Logo"
					/>
				</div>
				<div></div>

				<Cart cartOpen={cartOpen} setCartOpen={setCartOpen} />
			</div>
			<div id={'shopIndex__body'}>
				<Row>
					<Col xs md={3}>
						<Card
							src={cd}
							alt={'CD'}
							title={'CD CLASSIQUE'}
							onClick={() => navigate('/cd')}
						/>
					</Col>
					<Col xs md={3}>
						<Card
							src={tshirt}
							alt={'T-Shirt'}
							title={'T-SHIRT'}
							onClick={() => navigate('/tshirt')}
						/>
					</Col>
					<Col xs md={3}>
						<Card
							src={packClassic}
							alt={'Pack classique'}
							title={'PACK CLASSIQUE'}
							onClick={() => navigate('/classique')}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs md={3}>
						<Card
							src={packDistance}
							alt={'Pack Distance'}
							title={'PACK DISTANCE'}
							subTitle
							onClick={() => navigate('/distance')}
						/>
					</Col>
					<Col xs md={3}>
						<Card
							src={packDMB}
							alt={'Pack D.M.B.'}
							title={'PACK D.M.B.'}
							subTitle
							onClick={() => navigate('/dmb')}
						/>
					</Col>
					<Col xs md={3}>
						<Card
							src={packMoneytime}
							alt={'Pack Money time'}
							title={'PACK MONEY TIME'}
							subTitle
							onClick={() => navigate('/moneytime')}
						/>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default ShopIndex
