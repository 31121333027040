import { useState, useEffect } from 'react'
import { Button, Modal } from '../../../templates'
import { PayPalButtons } from '@paypal/react-paypal-js'
import { Checkbox } from '@mui/material'

const CartModal = ({ modalOpen, setModalOpen, total, description }) => {
	const style = { layout: 'vertical' },
		[optionIsSelected, setOptionIsSelected] = useState(false),
		[withdrawalSelected, setWithdrawalSelected] = useState(false),
		[deliverySelected, setDeliverySelected] = useState(false),
		[finalTotal, setFinalTotal] = useState(total),
		[finalDescription, setFinalDescription] = useState(description)

	useEffect(() => {
		setOptionIsSelected(withdrawalSelected || deliverySelected)
		if (withdrawalSelected) {
			setFinalTotal(total)
			setFinalDescription(`${finalDescription} - SP`)
		} else if (deliverySelected) {
			let newTotal = parseFloat(total) + 5
			setFinalTotal(newTotal.toFixed(2))
			setFinalDescription(`${finalDescription} - LV`)
		} else {
			setFinalTotal(total)
		}
	}, [withdrawalSelected, deliverySelected])

	return (
		<Modal
			open={modalOpen}
			setOpen={setModalOpen}
			content={{
				header: 'Payer',
				body: (
					<div id={'cart__modal-body'}>
						<div id={'cart__withdrawal'}>
							<div> Sélectionner votre mode de retrait:</div>
							<div>
								<Checkbox
									checked={withdrawalSelected}
									onChange={(e) => {
										let { checked } = e.target
										setWithdrawalSelected(checked)
										if (checked) {
											setDeliverySelected(!checked)
										}
									}}
								/>{' '}
								En boutique: Skyclub Vintage, 6 Rue au Lin,
								80000 Amiens
							</div>
							<div>
								<Checkbox
									checked={deliverySelected}
									onChange={(e) => {
										let { checked } = e.target
										setDeliverySelected(checked)
										if (checked) {
											setWithdrawalSelected(!checked)
										}
									}}
								/>{' '}
								En livraison (5 euros de frais de port)
							</div>
						</div>

						{optionIsSelected && (
							<div id={'cart__modal-total'}>
								Total: {finalTotal} €
							</div>
						)}

						{optionIsSelected && (
							<PayPalButtons
								style={style}
								disabled={false}
								forceReRender={[total, 'EUR', style]}
								fundingSource={undefined}
								createOrder={(data, actions) => {
									return actions.order
										.create({
											purchase_units: [
												{
													description,
													amount: {
														currency_code: 'EUR',
														value: finalTotal
													}
												}
											]
										})
										.then((orderId) => {
											// Your code here after create the order
											return orderId
										})
								}}
								onApprove={function (data, actions) {
									return actions.order
										.capture()
										.then(function () {
											alert('ok')
											// Your code here after capture the order
										})
								}}
							/>
						)}
					</div>
				),
				footer: (
					<div>
						<Button
							onClick={() => setModalOpen(false)}
							label={'Annuler'}
							color={'black'}
						/>
					</div>
				)
			}}
		/>
	)
}

export default CartModal
