import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from '@mui/material'

const Modal = ({ open, setOpen, content, maxWidth }) => {
	const { header, body, footer } = content

	return (
		<Dialog open={open} onClose={() => setOpen(false)} maxWidth={maxWidth}>
			<DialogTitle>{header}</DialogTitle>
			<DialogContent>{body}</DialogContent>
			<DialogActions>{footer}</DialogActions>
		</Dialog>
	)
}

export default Modal
